import {
  IResourceComponentsProps,
  useNavigation,
  usePermissions,
} from "@pankod/refine-core";
import { Edit, Form, Input, useForm } from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

export const ManagerEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm({});

  const { replace } = useNavigation();
  const { isSuccess } = usePermissions();

  if (!isSuccess) {
    replace("/u/managers");
    return null;
  }

  return (
    <Edit
      pageHeaderProps={{ title: "Изменение менджера", breadcrumb: {} }}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item label="ФИО" name="fullName">
          <Input />
        </Form.Item>
        <Form.Item label="Телефон" name="phone">
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  );
};
