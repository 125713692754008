import {
  IResourceComponentsProps,
  usePermissions,
  useShow,
} from "@pankod/refine-core";
import { Show, Row, Col, Descriptions } from "@pankod/refine-antd";

import styles from "./create.module.scss";

export const ProductsShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<any>({});
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { isSuccess } = usePermissions();

  return (
    <Show
      pageHeaderProps={{ title: "Просмотр продукта", breadcrumb: {} }}
      isLoading={isLoading}
      canEdit={isSuccess}
    >
      <Row>
        <Col className="gutter-row" span={12}>
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label="Название" span={12}>
              {record?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Описание" span={12}>
              <div
                dangerouslySetInnerHTML={{ __html: `${record?.description}` }}
              />
            </Descriptions.Item>
          </Descriptions>

          <Descriptions layout="vertical" bordered style={{ marginTop: 20 }}>
            <Descriptions.Item label="Изображение" span={12}>
              <div
                className={styles.wrapper}
                style={{
                  backgroundImage: `url(${record?.photoUrl?.url})`,
                }}
              />
            </Descriptions.Item>
          </Descriptions>

          <Descriptions
            layout="vertical"
            title="Типы серии"
            bordered
            style={{ marginTop: 20 }}
          >
            {record?.types?.map((type: any) => (
              <>
                <Descriptions.Item
                  label={type.name}
                  span={12}
                  style={{ borderTop: "1px solid black" }}
                >
                  {type.description}
                </Descriptions.Item>
                <Descriptions.Item label="Изображение" span={12}>
                  <div
                    className={styles.wrapper}
                    style={{
                      backgroundImage: `url(${type?.photoUrl?.url})`,
                    }}
                  />
                </Descriptions.Item>
              </>
            ))}
          </Descriptions>
        </Col>
      </Row>
    </Show>
  );
};
