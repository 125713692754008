import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import qs from "qs";
import Cookies from "js-cookie";

declare module "axios" {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

class Request {
  protected readonly instance: AxiosInstance;

  public constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_API!,
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.setHeader(Cookies.get("accessToken"));

    this._initRequestInterceptors();
  }

  private _initRequestInterceptors = () => {
    this.instance.interceptors.request.use(
      (request: AxiosRequestConfig) => ({
        ...request,
        paramsSerializer: (params: any) =>
          qs.stringify(params, { indices: false }),
      }),
      (err) => Promise.reject(err)
    );
  };
  // private refreshToken = async ({ config }: AxiosError) => {
  //   const { dispatch } = store

  //   dispatch(getRefreshRequesting())

  //   try {
  //     const {
  //       data: { access_token, refresh_token },
  //     } = await Auth.postRefreshTokens()

  //     this.setHeader(access_token)
  //     config.headers['Authorization'] = `Bearer ${access_token}`

  //     Cookie.set('access_token', access_token)
  //     Cookie.set('refresh_token', refresh_token)

  //     return Promise.resolve(dispatch(getRefreshSuccess()))
  //   } catch (error) {
  //     return Promise.reject(dispatch(logoutAuth()))
  //   }
  // }

  public setHeader = (accessToken?: string) => {
    this.instance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${accessToken}`;
  };

  public getInstance = () => this.instance;
}

export const req = new Request();
export const request = req.getInstance();
