import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  Progress,
  Select,
  Upload,
  useForm,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";
import { useCallback, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";

import { request } from "api/Request";

import styles from "./create.module.scss";

export const BannersCreate: React.FC<IResourceComponentsProps> = () => {
  const { form, formProps, saveButtonProps } = useForm({
    resource: "banners",
    redirect: "list",
  });

  const [defaultFileList, setDefaultFileList] = useState([]);
  const [formState, setFormState] = useState<any>({});
  const [progress, setProgress] = useState(0);

  const handleOnChange = useCallback(({ fileList }: any) => {
    setDefaultFileList(fileList);
  }, []);

  const upload = useCallback(
    async (options) => {
      const { onSuccess, onError, file, onProgress } = options;

      const formData = new FormData();

      const config = {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (event: any) => {
          const percent = Math.floor((event.loaded / event.total) * 100);

          setProgress(percent);

          if (percent === 100) {
            setTimeout(() => setProgress(0), 1000);
          }
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };

      formData.append("files", file);

      try {
        const { data } = await request.post(
          `${process.env.REACT_APP_API}/uploads`,
          formData,
          config
        );

        onSuccess("Ok");

        form.setFieldsValue({ file: data[0].id });

        setFormState((prevState: any) => ({
          ...prevState,
          file: data[0].url,
        }));
      } catch (err) {
        onError({ err });
      }
    },
    [form]
  );

  const handleInputChange = useCallback(
    (evt) =>
      setFormState((prevState: any) => ({
        ...prevState,
        [evt.target.name]: evt.target.value,
      })),
    []
  );

  return (
    <Create
      pageHeaderProps={{ title: "Создание баннера", breadcrumb: {} }}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Ключ"
          name="key"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Заголовок"
          name="title"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="title" onChange={handleInputChange} />
        </Form.Item>
        <Form.Item
          label="Текст на кнопке"
          name="buttonText"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input name="buttonText" onChange={handleInputChange} />
        </Form.Item>
        <Form.Item
          label="Ссылка на ресурс, куда ведет баннер"
          name="url"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Отображение" name="view">
          <Select defaultValue={false}>
            <Select.Option value={true}>Отображать</Select.Option>
            <Select.Option value={false}>Скрывать</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="file"
          label="Файл"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Upload
            accept="image/*"
            listType="picture-card"
            maxCount={1}
            onChange={(props) => handleOnChange(props)}
            defaultFileList={defaultFileList}
            customRequest={(props) => upload(props)}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Загрузка обложки
              </div>
            </div>
          </Upload>
          {progress > 0 ? <Progress percent={progress} /> : null}
        </Form.Item>

        <span style={{ color: "red" }}>
          *Это приблизительный вариант отображения. Текст отличается внешним
          видом.
        </span>
        <div
          className={styles.wrapper}
          style={{
            backgroundImage: `url(${formState?.file})`,
          }}
        >
          {formState?.title && (
            <h3 className={styles.wrapper_title}>{formState?.title}</h3>
          )}
          {formState?.buttonText && (
            <button type="button" className={styles.wrapper_button}>
              {formState?.buttonText}
            </button>
          )}
        </div>
      </Form>
    </Create>
  );
};
