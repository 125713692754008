import { IResourceComponentsProps } from "@pankod/refine-core";
import { Create, Form, Input, Upload, useForm } from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";
import { useCallback, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";

import { request } from "api/Request";

const { TextArea } = Input;

export const EventsCreate: React.FC<IResourceComponentsProps> = () => {
  const { form, formProps, saveButtonProps } = useForm({ redirect: "list" });

  const [defaultFileList, setDefaultFileList] = useState([]);

  const handleOnChange = useCallback(({ fileList }: any) => {
    setDefaultFileList(fileList);
  }, []);

  const upload = useCallback(
    async (options) => {
      const { onSuccess, onError, file } = options;

      const formData = new FormData();

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      formData.append("files", file);

      try {
        const { data } = await request.post(
          `${process.env.REACT_APP_API}/uploads`,
          formData,
          config
        );

        onSuccess("Ok");

        form.setFieldsValue({ file: data[0].id });
      } catch (err) {
        onError({ err });
      }
    },
    [form]
  );

  return (
    <Create
      pageHeaderProps={{ title: "Создание мероприятия", breadcrumb: {} }}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Название мероприятия"
          name="title"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="title" maxLength={300} />
        </Form.Item>
        <Form.Item
          label="Короткое название мероприятия"
          name="shortTitle"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="shortTitle" maxLength={100} />
        </Form.Item>
        <Form.Item
          label="Описание мероприятия"
          name="description"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <TextArea name="description" />
        </Form.Item>

        <Form.Item
          label="Дата"
          name="date"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="date" type="date" />
        </Form.Item>

        <Form.Item
          label="Время начала"
          name="timeStart"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="time" name="timeStart" />
        </Form.Item>
        <Form.Item
          label="Время окончания"
          name="timeEnd"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="time" name="timeEnd" />
        </Form.Item>

        <Form.Item
          label="Адрес"
          name="address"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="address" maxLength={150} />
        </Form.Item>

        <Form.Item
          name="file"
          label="Обложка"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Upload
            accept="image/*"
            listType="picture-card"
            maxCount={1}
            onChange={(props) => handleOnChange(props)}
            defaultFileList={defaultFileList}
            customRequest={(props) => upload(props)}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Загрузка обложки
              </div>
            </div>
          </Upload>
        </Form.Item>

        <Form.Item label="Ссылка на ВК" name={["socials", "vk"]}>
          <Input name="socials.vk" maxLength={300} />
        </Form.Item>
        <Form.Item label="Ссылка на Instagram" name={["socials", "instagram"]}>
          <Input name="socials.instagram" maxLength={300} />
        </Form.Item>
        <Form.Item label="Ссылка на Telegram" name={["socials", "telegram"]}>
          <Input name="socials.telegram" maxLength={300} />
        </Form.Item>
        <Form.Item label="Ссылка на OK" name={["socials", "ok"]}>
          <Input name="socials.ok" maxLength={300} />
        </Form.Item>
      </Form>
    </Create>
  );
};
