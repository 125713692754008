import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Button,
  Edit,
  Form,
  Input,
  Progress,
  Space,
  Upload,
  UploadFile,
  useForm,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";
import { useCallback, useEffect, useState } from "react";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { request } from "api/Request";

import styles from "./types.module.scss";
import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";
import Manufacturings from "components/Manufacturings";

export const ManufacturingTypesEdit: React.FC<
  IResourceComponentsProps
> = () => {
  const { form, formProps, saveButtonProps } = useForm();

  // markdown
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  const [defaultFileList, setDefaultFileList] = useState<UploadFile[][]>([[]]);
  const [progress, setProgress] = useState(0);

  const upload = useCallback(
    async (options) => {
      const { onSuccess, onError, file, onProgress } = options;

      const formData = new FormData();

      const config = {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (event: any) => {
          const percent = Math.floor((event.loaded / event.total) * 100);

          setProgress(percent);

          if (percent === 100) {
            setTimeout(() => setProgress(0), 1000);
          }
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };

      formData.append("files", file);

      try {
        const { data } = await request.post(
          `${process.env.REACT_APP_API}/uploads`,
          formData,
          config
        );

        onSuccess("Ok");

        form.setFieldsValue({ file: data[0].id });
      } catch (err) {
        onError({ err });
      }
    },
    [form]
  );

  useEffect(() => {
    setDefaultFileList([
      [
        {
          uid: "-2",
          name: formProps?.initialValues?.icon.original_filename,
          status: "done",
          url: formProps?.initialValues?.icon.url,
        },
      ],
      [
        {
          uid: "-1",
          name: formProps?.initialValues?.file.original_filename,
          status: "done",
          url: formProps?.initialValues?.file.url,
        },
      ],
      [],
    ]);
  }, [
    formProps?.initialValues?.file.original_filename,
    formProps?.initialValues?.file.url,
    formProps?.initialValues?.icon.original_filename,
    formProps?.initialValues?.icon.url,
  ]);

  return (
    <Edit
      pageHeaderProps={{ title: "Изменение типа продукции", breadcrumb: {} }}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Название"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="name" />
        </Form.Item>

        <Form.Item
          label="Короткое название"
          name="shortName"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="shortName" />
        </Form.Item>

        <Form.Item
          label="Описание"
          name="description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>

        <Form.Item
          label="Короткое описание"
          name="shortDescription"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="shortDescription" />
        </Form.Item>

        <Form.List name="additionalInfo">
          {(fields, { add, remove }) => (
            <>
              <div className={styles.arrayProducts}>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Space
                    key={key}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 8,
                      backgroundColor: "#eeeeee",
                      borderRadius: 20,
                      padding: 15,
                    }}
                    align="baseline"
                  >
                    <MinusCircleOutlined onClick={() => remove(name)} />

                    <Form.Item
                      {...restField}
                      label="Заголовок"
                      name={[name, "title"]}
                      rules={[{ required: true, message: "Missing title" }]}
                      style={{ width: 500 }}
                    >
                      <Input placeholder="Заголовок доп. информации" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Описание"
                      name={[name, "description"]}
                      rules={[
                        { required: true, message: "Missing description" },
                      ]}
                      style={{ width: 500 }}
                    >
                      <Input placeholder="Описание" />
                    </Form.Item>
                  </Space>
                ))}
              </div>
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Добавить доп. информацию
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item
          name="icon"
          label="Иконка"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Upload
            accept="image/*"
            listType="picture-card"
            maxCount={1}
            defaultFileList={defaultFileList[0]}
            customRequest={(props) => upload(props)}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Загрузка иконки
              </div>
            </div>
          </Upload>
          {progress > 0 ? <Progress percent={progress} /> : null}
        </Form.Item>

        <Form.Item
          name="file"
          label="Файл"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Upload
            accept="image/*"
            listType="picture-card"
            maxCount={1}
            defaultFileList={defaultFileList[1]}
            customRequest={(props) => upload(props)}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Загрузка обложки
              </div>
            </div>
          </Upload>
          {progress > 0 ? <Progress percent={progress} /> : null}
        </Form.Item>

        <Manufacturings form={form} formProps={formProps} />
      </Form>
    </Edit>
  );
};
