import { IResourceComponentsProps, usePermissions } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
} from "@pankod/refine-antd";
import { CompanyRo } from "interfaces/companies/ro/company.ro";
import Cookies from "js-cookie";
import { UsersRo } from "interfaces/users/types/ro/users.ro";

export const CompaniesList: React.FC<IResourceComponentsProps> = () => {
  const { isSuccess } = usePermissions();

  const { tableProps } = useTable<CompanyRo>({
    resource: "company",
    initialPageSize: 10,
    initialCurrent: 1,
  });

  const user = JSON.parse(Cookies.get("user") || "{}") as UsersRo;

  const { data, count } = (tableProps.dataSource as any) || [];

  return (
    <List>
      <Table
        {...tableProps}
        rowKey="id"
        dataSource={data}
        pagination={{ pageSize: 10, total: count }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title="Название"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="inn"
          key="inn"
          title="ИНН"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="success"
          key="success"
          title="Подтверждена"
          render={(value) => (
            <TextField value={value ? "Подтверждена" : "Не подтверждена"} />
          )}
        />
        <Table.Column<CompanyRo>
          title="Управление"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              {(isSuccess || record?.manager?.id === user?.id) && (
                <EditButton hideText size="small" recordItemId={record.id} />
              )}
              <ShowButton hideText size="small" recordItemId={record.id} />
              {isSuccess && (
                <DeleteButton hideText size="small" recordItemId={record.id} />
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
