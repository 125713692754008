import { memo } from "react";

import { Button, Form, Input, InputNumber, Space } from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import styles from "../../../pages/products/create.module.scss";
import BlockPlatformTypesSelect from "./BlockPlatformTypesSelect";

const BlockPlatformTypes = ({ typesList, name, ...restField }: any) => {
  return (
    <Form.List {...restField} name={name}>
      {(fields, { add, remove }) => (
        <>
          <div className={styles.arrayProducts}>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                style={{
                  width: 400,
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 8,
                  backgroundColor: "#eeeeee",
                  borderRadius: 20,
                  padding: 15,
                }}
                align="baseline"
              >
                <MinusCircleOutlined onClick={() => remove(name)} />

                <Form.Item
                  {...restField}
                  label="Сортировка"
                  name={[name, "sort"]}
                  rules={[{ required: true, message: "Missing sort" }]}
                >
                  <InputNumber placeholder="Сортировка" />
                </Form.Item>
                <Form.Item
                  {...restField}
                  label="Заголовок"
                  name={[name, "title"]}
                  rules={[
                    {
                      required: true,
                      message: "Missing title",
                    },
                  ]}
                >
                  <Input placeholder="Заголовок" />
                </Form.Item>
                <Form.Item
                  {...restField}
                  label="Ключ"
                  name={[name, "key"]}
                  rules={[
                    {
                      required: true,
                      message: "Missing key",
                    },
                  ]}
                >
                  <Input placeholder="Ключ" />
                </Form.Item>
                <Form.Item
                  {...restField}
                  label="Описание"
                  name={[name, "description"]}
                  rules={[
                    {
                      required: true,
                      message: "Missing description",
                    },
                  ]}
                >
                  <Input placeholder="Описание" />
                </Form.Item>
                <BlockPlatformTypesSelect
                  {...restField}
                  name={[name, "components"]}
                  typesList={typesList}
                />
              </Space>
            ))}
          </div>
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              Добавить тип платформы
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default memo(BlockPlatformTypes);
