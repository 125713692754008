import {
  IResourceComponentsProps,
  usePermissions,
  useShow,
} from "@pankod/refine-core";
import { Show, Row, Col, Descriptions } from "@pankod/refine-antd";

import styles from "./create.module.scss";

export const ConfiguratorShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<any>({});
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { isSuccess } = usePermissions();

  return (
    <Show
      pageHeaderProps={{ title: "Просмотр комплектующей", breadcrumb: {} }}
      isLoading={isLoading}
      canEdit={isSuccess}
    >
      <Row>
        <Col className="gutter-row" span={12}>
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label="Ключ" span={12}>
              {record?.key}
            </Descriptions.Item>
            <Descriptions.Item label="Отображение" span={12}>
              {record?.active === true ? "Да" : "Нет"}
            </Descriptions.Item>
            <Descriptions.Item label="Название" span={12}>
              {record?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Название в конфигураторе" span={12}>
              {record?.fullName}
            </Descriptions.Item>
            <Descriptions.Item label="Описание" span={12}>
              {record?.description || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Цена" span={1}>
              {record?.price}
            </Descriptions.Item>
            <Descriptions.Item label="Тип комплектующей" span={1}>
              {record?.type.toUpperCase()}
            </Descriptions.Item>
            <Descriptions.Item label="Акцентные слова" span={1}>
              {record?.accent?.map(({ name }: any) => `${name}, `)}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions layout="vertical" bordered style={{ marginTop: 20 }}>
            <Descriptions.Item label="Изображение" span={12}>
              {record?.photoUrl ? (
                <div
                  className={styles.wrapper}
                  style={{
                    backgroundImage: `url(${record?.photoUrl})`,
                  }}
                />
              ) : (
                "-"
              )}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </Show>
  );
};
