import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import {
  Show,
  Typography,
  Tag,
  Row,
  Col,
  MarkdownField,
} from "@pankod/refine-antd";

const { Title, Text } = Typography;

export const SettingsShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<any>({
    resource: "articles",
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show
      pageHeaderProps={{ title: "Просмотр Статьи", breadcrumb: {} }}
      isLoading={isLoading}
    >
      <Row>
        <Col className="gutter-row" span={12}>
          <Title level={5}>Ключ</Title>
          <Text>
            <Tag>{record?.key}</Tag>
          </Text>
          {record?.file && (
            <>
              <Title level={5}>Документ</Title>
              <Text>
                <a
                  href={record?.file.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Tag color="green">{record?.file.original_filename}</Tag>
                </a>
              </Text>
            </>
          )}
          <Title level={5}>Содержимое</Title>
          <MarkdownField value={record?.markup} />
        </Col>
      </Row>
    </Show>
  );
};
