import {
  IResourceComponentsProps,
  usePermissions,
  useShow,
} from "@pankod/refine-core";
import { Show, Typography, Tag, Card, Row, Col } from "@pankod/refine-antd";
import { format } from "date-fns";
import { RoleObject } from "interfaces/users/roles/role.enum";
import { Stages } from "interfaces/deals/stages.enum";
import { Statuses } from "interfaces/deals/status.enum";

const { Title, Text } = Typography;

export const DealsShow: React.FC<IResourceComponentsProps> = () => {
  const { isSuccess } = usePermissions();
  const { queryResult } = useShow({ resource: "sales/deals" });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const user = record?.partner || record?.employeer;

  return (
    <Show
      pageHeaderProps={{ title: "Просмотр сделки", breadcrumb: {} }}
      isLoading={isLoading}
      canEdit={isSuccess}
    >
      <Row>
        <Col className="gutter-row" span={12}>
          <Title level={5}>Наименование заказчика</Title>
          <Text>
            <Tag>{record?.companyName}</Tag>
          </Text>
          <Title level={5}>Сумма сделки</Title>
          <Text>
            <Tag>{record?.amount}</Tag>
          </Text>
          <Title level={5}>Дистрибьютер</Title>
          <Text>
            <Tag>{record?.distributor}</Tag>
          </Text>
          <Title level={5}>Название конкурса</Title>
          <Text>
            <Tag>{record?.name}</Tag>
          </Text>
          <Title level={5}>Ссылка на конкурс</Title>
          <Text>
            <a href={record?.url} target="_blank" rel="noopener noreferrer">
              <Tag>{record?.url}</Tag>
            </a>
          </Text>
          <Title level={5}>Дата конкурса</Title>
          <Text>
            <Tag>
              {record?.date && format(new Date(record?.date), "dd.MM.yyyy")}
            </Tag>
          </Text>
          <Title level={5}>ИНН заказчика</Title>
          <Text>
            <Tag>{record?.inn}</Tag>
          </Text>
          <Title level={5}>Юр. адрес заказчика с индексом</Title>
          <Text>
            <Tag>
              {record?.address} {record?.index}
            </Tag>
          </Text>
          <Title level={5}>Юр.лицо, участника</Title>
          <Text>
            <Tag>{record?.customer}</Tag>
          </Text>
          <Title level={5}>Ведущий менеджер</Title>
          <Text>
            <Tag>{record?.manager}</Tag>
          </Text>
          <Title level={5}>Сайт компании</Title>
          <Text>
            <a
              href={record?.companyUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Tag>{record?.companyUrl}</Tag>
            </a>
          </Text>
        </Col>
        <Col className="gutter-row" span={12}>
          <Card>
            <Title level={5}>Спецификация</Title>
            <Text>
              <a
                href={record?.file.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Tag color="green">{record?.file.original_filename}</Tag>
              </a>
            </Text>
          </Card>
          <Card style={{ marginTop: "20px" }}>
            <Title level={5}>Статус сделки</Title>
            <Text>
              <Tag>{Statuses[record?.status as keyof typeof Statuses]}</Tag>
            </Text>
            <Title level={5}>Этап сделки</Title>
            <Text>
              <Tag>{Stages[record?.stage as number]}</Tag>
            </Text>
          </Card>
          <Card style={{ marginTop: "20px" }}>
            <Title level={5}>ФИО заказчика</Title>
            <Text>
              <Tag>{user?.fullName}</Tag>
            </Text>
            <Title level={5}>Должность заказчика</Title>
            <Text>
              <Tag>
                {record?.partner ? RoleObject.Partner : RoleObject.Employee}
              </Tag>
            </Text>
            <Title level={5}>Контактный телефон заказчика</Title>
            <Text>
              <Tag>{user?.phone}</Tag>
            </Text>
            <Title level={5}>Email заказчика</Title>
            <Text>
              <Tag>{user?.email}</Tag>
            </Text>
          </Card>
          <Card style={{ marginTop: "20px" }}>
            <Title level={5}>% Выигрыша сделки</Title>
            <Text>
              <Tag>{record?.percent}%</Tag>
            </Text>
            <Title level={5}>Конкуренты</Title>
            <Text>
              <Tag>{record?.сompetitors}</Tag>
            </Text>
            <Title level={5}>Комментарии</Title>
            <Text>
              <Tag>{record?.comments}</Tag>
            </Text>
          </Card>
        </Col>
      </Row>
    </Show>
  );
};
