import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-antd";
import "@pankod/refine-antd/dist/styles.min.css";
import routerProvider from "@pankod/refine-react-router-v6";
import dataProvider from "@pankod/refine-simple-rest";
import { CompaniesList, CompanyEdit, CompaniesShow } from "pages/companies";
import { EmployeesEdit, EmployeesList, EmployeesShow } from "pages/employees";
import {
  ManagerCreate,
  ManagerEdit,
  ManagersList,
  ManagersShow,
} from "pages/managers";
import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from "components/layout";
import authProvider from "authProvider";
import { Login } from "pages/login";
import { request } from "api/Request";
import {
  SettingsList,
  SettingsCreate,
  SettingsEdit,
  SettingsShow,
} from "pages/settings";
import { ContactUsList, ContactUsShow } from "pages/contactUs";
import {
  MaterialsCreate,
  MaterialsEdit,
  MaterialsList,
  MaterialsShow,
} from "pages/materials";
import { PartnersEdit, PartnersList, PartnersShow } from "pages/partners";
import { CertificatesList } from "pages/certificates";
import { DealsEdit, DealsList, DealsShow } from "pages/deals";
import { FundShow, FundsList } from "pages/funds";
import {
  BannersCreate,
  BannersEdit,
  BannersList,
  BannersShow,
} from "pages/banners";
import {
  ConfiguratorCreate,
  ConfiguratorList,
  ConfiguratorShow,
  ConfiguratorEdit,
} from "pages/configurator";
import {
  ProductsCreate,
  ProductsEdit,
  ProductsList,
  ProductsShow,
} from "pages/products";
import { OrdersEdit, OrdersList, OrdersShow } from "pages/orders";
import {
  ManufacturingTypesCreate,
  ManufacturingTypesEdit,
  ManufacturingTypesList,
  ManufacturingTypesShow,
} from "pages/manufacturingTypes";
import { EventsList } from "pages/events";
import { EventsCreate } from "pages/events";
import { EventsEdit } from "pages/events";
import { EventsShow } from "pages/events";
import {
  WebinarsCreate,
  WebinarsEdit,
  WebinarsList,
  WebinarsShow,
} from "pages/webinars";

const App = () => (
  <Refine
    authProvider={authProvider}
    notificationProvider={notificationProvider}
    ReadyPage={ReadyPage}
    catchAll={<ErrorComponent />}
    routerProvider={routerProvider}
    dataProvider={dataProvider(process.env.REACT_APP_API!, request)}
    resources={[
      { name: "u", options: { label: "Пользователи" } },
      {
        name: "employees",
        options: { label: "Сотрудники", route: "employees" },
        parentName: "u",
        list: EmployeesList,
        edit: EmployeesEdit,
        show: EmployeesShow,
      },
      {
        name: "managers",
        options: { label: "Менеджеры", route: "managers" },
        parentName: "u",
        list: ManagersList,
        edit: ManagerEdit,
        show: ManagersShow,
        create: ManagerCreate,
      },
      {
        name: "partners",
        options: { label: "Партнеры", route: "partners" },
        parentName: "u",
        list: PartnersList,
        edit: PartnersEdit,
        show: PartnersShow,
      },
      {
        name: "company",
        options: { label: "Компании" },
        list: CompaniesList,
        edit: CompanyEdit,
        show: CompaniesShow,
      },
      {
        name: "articles",
        options: { label: "Статьи" },
        list: SettingsList,
        create: SettingsCreate,
        edit: SettingsEdit,
        show: SettingsShow,
      },
      {
        name: "support/contactUs",
        options: { label: "Поддержка", route: "support/contactUs" },
        list: ContactUsList,
        show: ContactUsShow,
      },
      {
        name: "certificates",
        options: { label: "История сертификатов", route: "certificates" },
        list: CertificatesList,
      },
      {
        name: "marketing/materials",
        options: {
          label: "Маркетинговые материалы",
          route: "marketing/materials",
        },
        list: MaterialsList,
        show: MaterialsShow,
        create: MaterialsCreate,
        edit: MaterialsEdit,
      },
      {
        name: "sales/deals",
        options: { label: "Регистратор сделок", route: "sales/deals" },
        list: DealsList,
        show: DealsShow,
        edit: DealsEdit,
      },
      {
        name: "marketing/funds",
        options: { label: "Маркетинговый бюджет", route: "marketing/funds" },
        list: FundsList,
        show: FundShow,
      },
      {
        name: "banners",
        options: { label: "Баннеры" },
        list: BannersList,
        create: BannersCreate,
        edit: BannersEdit,
        show: BannersShow,
      },
      { name: "c", options: { label: "Конфигуратор" } },
      {
        name: "components",
        options: { label: "Комплектующие", route: "components" },
        parentName: "c",
        list: ConfiguratorList,
        create: ConfiguratorCreate,
        show: ConfiguratorShow,
        edit: ConfiguratorEdit,
      },
      {
        name: "products",
        options: { label: "Продукты", route: "products" },
        parentName: "c",
        list: ProductsList,
        create: ProductsCreate,
        edit: ProductsEdit,
        show: ProductsShow,
      },
      {
        name: "orders",
        options: { label: "Заказы", route: "orders" },
        parentName: "c",
        list: OrdersList,
        edit: OrdersEdit,
        show: OrdersShow,
      },
      { name: "p", options: { label: "Продукция" } },
      {
        name: "manufacturingTypes",
        options: { label: "Типы продукции", route: "manufacturingTypes" },
        parentName: "p",
        list: ManufacturingTypesList,
        create: ManufacturingTypesCreate,
        edit: ManufacturingTypesEdit,
        show: ManufacturingTypesShow,
      },
      {
        name: "events",
        options: { label: "Мероприятия", route: "events" },
        list: EventsList,
        create: EventsCreate,
        edit: EventsEdit,
        show: EventsShow,
      },
      {
        name: "webinars",
        options: { label: "Вебинары", route: "webinars" },
        list: WebinarsList,
        create: WebinarsCreate,
        edit: WebinarsEdit,
        show: WebinarsShow,
      },
    ]}
    LoginPage={Login}
    Title={Title}
    Header={Header}
    Sider={Sider}
    Footer={Footer}
    Layout={Layout}
    OffLayoutArea={OffLayoutArea}
  />
);

export default App;
