import {
  IResourceComponentsProps,
  usePermissions,
  useRouterContext,
  useShow,
} from "@pankod/refine-core";
import { Show, Typography, Tag, Row, Col } from "@pankod/refine-antd";
import { UsersRo } from "interfaces/users/types/ro/users.ro";
import { Role } from "interfaces/users/roles/role.enum";

const { Title, Text } = Typography;

export const ManagersShow: React.FC<IResourceComponentsProps> = () => {
  const { useParams } = useRouterContext();
  const { id } = useParams() as { id: string };
  const { queryResult } = useShow<UsersRo>({ id });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { isSuccess } = usePermissions();

  return (
    <Show
      resource="managers"
      pageHeaderProps={{ title: "Просмотр менеджера", breadcrumb: {} }}
      isLoading={isLoading}
      canEdit={isSuccess}
    >
      <Row>
        <Col className="gutter-row" span={12}>
          <Title level={5}>ФИО</Title>
          <Text>
            <Tag>{record?.fullName}</Tag>
          </Text>
          <Title level={5}>Email</Title>
          <Text>
            <Tag>{record?.email}</Tag>
          </Text>
          <Title level={5}>Номер тел.</Title>
          <Text>
            <Tag>{record?.phone}</Tag>
          </Text>
          <Title level={5}>Роль</Title>
          <Text>
            <Tag>{Role[record?.role as any]}</Tag>
          </Text>
        </Col>
      </Row>
    </Show>
  );
};
