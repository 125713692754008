import { IResourceComponentsProps, usePermissions } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
} from "@pankod/refine-antd";
import { UsersRo } from "interfaces/users/types/ro/users.ro";
import { Role, RoleObject } from "interfaces/users/roles/role.enum";

export const ManagersList: React.FC<IResourceComponentsProps> = () => {
  const { isSuccess } = usePermissions();
  const { tableProps } = useTable<any>({
    initialPageSize: 10,
    initialCurrent: 1,
  });

  const { data, count } = (tableProps.dataSource as any) || [];

  return (
    <List
      pageHeaderProps={{
        title: "Менеджеры",
        subTitle: "Менеджеры платформы",
      }}
      canCreate={isSuccess}
    >
      <Table
        {...tableProps}
        rowKey="id"
        dataSource={data}
        pagination={{ pageSize: 10, total: count }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="fullName"
          key="fullName"
          title="ФИО"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="email"
          key="email"
          title="Email"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="phone"
          key="phone"
          title="Телефон"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="role"
          key="role"
          title="Роль"
          render={(role) => (
            <TextField
              value={RoleObject[Role[role] as keyof typeof RoleObject]}
            />
          )}
        />
        <Table.Column<UsersRo>
          title="Управление"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              {isSuccess && (
                <EditButton
                  resourceNameOrRouteName="managers"
                  hideText
                  size="small"
                  recordItemId={record.id}
                />
              )}
              <ShowButton
                resourceNameOrRouteName="managers"
                hideText
                size="small"
                recordItemId={record.id}
              />
              {isSuccess && (
                <DeleteButton
                  resourceNameOrRouteName="managers"
                  hideText
                  size="small"
                  recordItemId={record.id}
                />
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
