import { useCallback } from "react";
import { nanoid } from "nanoid";

import { PlayCircleOutlined } from "@ant-design/icons";
import {
  IResourceComponentsProps,
  useNavigation,
  usePermissions,
} from "@pankod/refine-core";
import { Create, Form, Input, useForm } from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

export const ManagerCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, form } = useForm({
    redirect: "list",
  });

  const handlePassword = useCallback(
    () => form.setFieldsValue({ password: nanoid() }),
    [form]
  );

  const { replace } = useNavigation();
  const { isSuccess } = usePermissions();

  if (!isSuccess) {
    replace("/u/managers");
    return null;
  }

  return (
    <Create
      pageHeaderProps={{ title: "Создание менджера", breadcrumb: {} }}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item label="ФИО" name="fullName">
          <Input />
        </Form.Item>
        <Form.Item label="Телефон" name="phone">
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input />
        </Form.Item>
        <Form.Item label="Password" name="password">
          <Input addonAfter={<PlayCircleOutlined onClick={handlePassword} />} />
        </Form.Item>
      </Form>
    </Create>
  );
};
