import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { Show, Row, Col, Descriptions } from "@pankod/refine-antd";

import styles from "./create.module.scss";

export const WebinarsShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<any>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show
      pageHeaderProps={{ title: "Просмотр мероприяти", breadcrumb: {} }}
      isLoading={isLoading}
    >
      <Row>
        <Col className="gutter-row" span={12}>
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label="ID" span={12}>
              {record?.id}
            </Descriptions.Item>
            <Descriptions.Item label="Название" span={12}>
              {record?.title}
            </Descriptions.Item>
            <Descriptions.Item label="Ссылка" span={12}>
              {record?.url}
            </Descriptions.Item>
            <Descriptions.Item label="Обложка" span={12}>
              <div
                className={styles.wrapper}
                style={{
                  backgroundImage: `url(${record?.file?.url})`,
                }}
              />
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </Show>
  );
};
