export enum Stages {
  // Обработка
  "Обработка заказа",
  // В_ПРОИЗВОДСТВЕ
  "В производстве",
  // Сборка
  "Сборка",
  // Склад
  "Передача на склад",
  // Отгрузка
  "Отгрузка",
}
