import {
  IResourceComponentsProps,
  useList,
  usePermissions,
} from "@pankod/refine-core";
import {
  AutoComplete,
  DeleteButton,
  EditButton,
  Input,
  List,
  ShowButton,
  Space,
  Table,
  TextField,
  Typography,
  useTable,
} from "@pankod/refine-antd";
import { useEffect, useState } from "react";

import debounce from "lodash/debounce";

import { SearchOutlined } from "@ant-design/icons";
import RouterProvider from "@pankod/refine-react-router-v6";

const { Text } = Typography;

const { Link } = RouterProvider;

export type IComponent = {
  id: number;
  created_at: Date;
  key: string;
  name: string;
  fullName: string;
  type: string;
  description: string;
  photoUrl: number;
  active: boolean;
  price: number;
  accent: string[];
};

export interface IOptionGroup {
  value: string;
  label: string | React.ReactNode;
}

export interface IOptions {
  label: string | React.ReactNode;
  options: IOptionGroup[];
}

const renderItem = (title: string, resource: string, id: number) => {
  return {
    value: title,
    label: (
      <Link to={`/${resource}/show/${id}`}>
        <Text>{title}</Text>
      </Link>
    ),
  };
};

export const ConfiguratorList: React.FC<IResourceComponentsProps> = () => {
  const [value, setValue] = useState("");
  const [options, setOptions] = useState<IOptions[]>([]);

  const { isSuccess } = usePermissions();

  const { tableProps } = useTable({
    initialPageSize: 10,
    initialCurrent: 1,
  });

  const { data, count } = (tableProps.dataSource as any) || [];

  const { refetch: refetchComponents } = useList<IComponent>({
    resource: "components",
    config: {
      filters: [{ field: "name", operator: "contains", value }],
      pagination: {
        current: 1,
        pageSize: 1000000,
      },
    },
    queryOptions: {
      enabled: false,
      onSuccess: ({ data: d }) => {
        const { data } = d as unknown as { data: IComponent[] };
        const postOptionGroup = data.map(({ name, id }) =>
          renderItem(name, "c/components", id)
        );

        if (postOptionGroup.length > 0) {
          setOptions([
            {
              label: "Результат поиска",
              options: postOptionGroup,
            },
          ]);
        } else {
          setOptions([
            {
              label: "Результат поиска",
              options: [],
            },
          ]);
        }
      },
    },
  });

  useEffect(() => {
    refetchComponents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <List>
      <AutoComplete
        style={{ width: "100%", marginBottom: 20 }}
        options={options}
        filterOption={false}
        onSearch={debounce((value: string) => setValue(value), 500)}
      >
        <Input
          size="large"
          placeholder="Поиск по названию"
          suffix={<SearchOutlined />}
        />
      </AutoComplete>
      <Table
        {...tableProps}
        rowKey="slug"
        dataSource={data}
        pagination={{ pageSize: 10, total: count }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="key"
          key="key"
          title="Ключ"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title="Название"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="fullName"
          key="fullName"
          title="Название в конфигураторе"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="type"
          key="type"
          title="Тип комплектующей"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="price"
          key="price"
          title="Цена(руб.)"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          title="Управление"
          dataIndex="actions"
          render={(_, record: any) => (
            <Space>
              {isSuccess && (
                <EditButton hideText size="small" recordItemId={record.id} />
              )}
              <ShowButton hideText size="small" recordItemId={record.id} />
              {isSuccess && (
                <DeleteButton hideText size="small" recordItemId={record.id} />
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
