import {
  IResourceComponentsProps,
  useNavigation,
  usePermissions,
  useSelect,
} from "@pankod/refine-core";
import { Edit, Form, Input, useForm, Select } from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";
import { Status } from "interfaces/companies/enums/status.enum";
import Cookies from "js-cookie";
import { UsersRo } from "interfaces/users/types/ro/users.ro";

export const CompanyEdit: React.FC<IResourceComponentsProps> = () => {
  const { isSuccess } = usePermissions();

  const { formProps, saveButtonProps } = useForm();
  const selectProps = useSelect({
    resource: "managers",
  });

  const defaultOption = {
    optionLabel: formProps.initialValues?.manager?.fullName || "",
    optionValue: formProps.initialValues?.manager?.id || "",
  };

  const { replace } = useNavigation();

  const user = JSON.parse(Cookies.get("user") || "{}") as UsersRo;

  if (formProps.initialValues?.manager?.id === user?.id || isSuccess)
    return (
      <Edit
        pageHeaderProps={{ title: "Изменение компании", breadcrumb: {} }}
        saveButtonProps={saveButtonProps}
      >
        <Form {...formProps} layout="vertical">
          <Form.Item label="Название" name="name">
            <Input disabled />
          </Form.Item>
          <Form.Item label="ИНН" name="inn">
            <Input disabled />
          </Form.Item>
          <Form.Item label="Email" name="email">
            <Input />
          </Form.Item>
          <Form.Item label="Юридический адрес" name="legalAddress">
            <Input />
          </Form.Item>
          <Form.Item label="Фактический адрес" name="actualAddress">
            <Input />
          </Form.Item>
          <Form.Item label="Подтверждение" name="success">
            <Select defaultValue={+formProps.initialValues?.success}>
              <Select.Option value={null}>Нейтральное состояние</Select.Option>
              <Select.Option value={true}>Подтверждена</Select.Option>
              <Select.Option value={false}>Не подтверждена</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Статус" name="status">
            <Select defaultValue={+formProps.initialValues?.status}>
              {Object.keys(Status)
                .filter((v) => isNaN(Number(v)))
                .map((status) => (
                  <Select.Option value={Status[status as any]}>
                    {status}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          {isSuccess && (
            <Form.Item label="Менеджер" name="managerId">
              <Select
                {...selectProps}
                options={(
                  (selectProps.queryResult.data?.data as any)?.data || []
                ).map((item: any) => ({
                  label: item.fullName,
                  value: item.id,
                }))}
                defaultValue={
                  defaultOption.optionLabel ||
                  "У данной компании нет менеджера..."
                }
              />
            </Form.Item>
          )}
        </Form>
      </Edit>
    );

  replace("/company");
  return null;
};
