import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Button,
  Create,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Upload,
  useForm,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";
import { useCallback, useState } from "react";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

import { request } from "api/Request";
import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";

import styles from "./create.module.scss";

export const ConfiguratorCreate: React.FC<IResourceComponentsProps> = () => {
  const { form, formProps, saveButtonProps } = useForm({
    resource: "components",
    redirect: "list",
  });

  // markdown
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  const upload = useCallback(
    async (options: any) => {
      const { onSuccess, onError, file } = options;

      const formData = new FormData();

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      formData.append("files", file);

      try {
        const { data } = await request.post(
          `${process.env.REACT_APP_API}/uploads`,
          formData,
          config
        );

        onSuccess("Ok");

        form.setFieldsValue({ photoUrl: data[0].id });
      } catch (err) {
        onError({ err });
      }
    },
    [form]
  );

  return (
    <Create
      pageHeaderProps={{ title: "Загрузка комплектующих", breadcrumb: {} }}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Ключ"
          name="key"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="key" />
        </Form.Item>

        <Form.Item
          label="Название"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="name" />
        </Form.Item>

        <Form.Item
          label="Название в конфигураторе"
          name="fullName"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="fullName" />
        </Form.Item>

        <Form.Item
          label="Тип комплектующей"
          name="type"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="type" placeholder="cpu" />
        </Form.Item>

        <Form.Item
          label="Описание"
          name="description"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>

        <Form.Item label="Отображение" name="active">
          <Select defaultValue={true}>
            <Select.Option value={true}>Отображать</Select.Option>
            <Select.Option value={false}>Скрывать</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Цена"
          name="price"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber name="price" placeholder="Руб." />
        </Form.Item>

        <Form.List name="accent">
          {(fields, { add, remove }) => (
            <>
              <div className={styles.arrayProducts}>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 8,
                      backgroundColor: "#eeeeee",
                      borderRadius: 20,
                      padding: 15,
                    }}
                    align="baseline"
                  >
                    <MinusCircleOutlined onClick={() => remove(name)} />

                    <Form.Item
                      {...restField}
                      label="Слово"
                      name={[name, "name"]}
                      rules={[{ required: true, message: "Missing name" }]}
                      style={{ width: 250 }}
                    >
                      <Input placeholder="Например: Акция" maxLength={20} />
                    </Form.Item>
                  </Space>
                ))}
              </div>
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Добавить акцентное слово
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item
          name="photoUrl"
          label="Фотография"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Upload
            accept="image/*"
            listType="picture-card"
            maxCount={1}
            customRequest={(props) => upload(props)}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Загрузка изображения
              </div>
            </div>
          </Upload>
        </Form.Item>
      </Form>
    </Create>
  );
};
