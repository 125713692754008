import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  Space,
  DeleteButton,
  EditButton,
  ShowButton,
} from "@pankod/refine-antd";
import { ManufacturingTypesDataRo } from "interfaces/manufacturingTypes/types/ro/manufacturingTypes.ro";

export const ManufacturingTypesList: React.FC<
  IResourceComponentsProps
> = () => {
  const { tableProps } = useTable({
    resource: "manufacturingTypes",
    initialPageSize: 10,
    initialCurrent: 1,
  });

  const { data, count } =
    (tableProps.dataSource as unknown as ManufacturingTypesDataRo) || {
      data: [],
      count: 0,
    };

  return (
    <List>
      <Table
        {...tableProps}
        rowKey="slug"
        dataSource={data}
        pagination={{ pageSize: 10, total: count }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title="Название"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="description"
          key="description"
          title="Описание"
          render={(value) => <TextField value={`${value.slice(0, 100)}...`} />}
        />
        <Table.Column
          title="Управление"
          dataIndex="actions"
          render={(_, record: any) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
