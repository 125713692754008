import { memo } from "react";

import { Button, Form, Input, Space } from "@pankod/refine-antd";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const ManufacturingsInfo = ({ name, ...restField }: any) => {
  return (
    <Form.List {...restField} name={name}>
      {(fields, { add, remove }) => (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                style={{
                  width: 400,
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 8,
                  backgroundColor: "#ddd",
                  borderRadius: 20,
                  padding: 15,
                }}
                align="baseline"
              >
                <MinusCircleOutlined onClick={() => remove(name)} />

                <Form.Item
                  {...restField}
                  label="Заголовок"
                  name={[name, "label"]}
                  style={{
                    width: 370,
                  }}
                  rules={[{ required: true, message: "Missing label" }]}
                >
                  <Input placeholder="Например: 'Диагональ экрана (дюйм)'" />
                </Form.Item>

                <Form.Item
                  {...restField}
                  label="Значение"
                  name={[name, "value"]}
                  style={{
                    width: 370,
                  }}
                  rules={[{ required: true, message: "Missing value" }]}
                >
                  <Input placeholder="Например: 27'" />
                </Form.Item>

                <Form.Item
                  {...restField}
                  label="Тултип"
                  name={[name, "tooltip"]}
                  style={{
                    width: 370,
                  }}
                  rules={[{ required: false, message: "Missing tooltip" }]}
                >
                  <Input />
                </Form.Item>
              </Space>
            ))}
          </div>
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              Добавить характеристику
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default memo(ManufacturingsInfo);
