import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { Show, Typography, Tag, Row, Col, Image } from "@pankod/refine-antd";

const { Title, Text } = Typography;

export const MaterialsShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow({
    resource: "marketing/materials",
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show
      pageHeaderProps={{ title: "Просмотр материала", breadcrumb: {} }}
      isLoading={isLoading}
    >
      <Row>
        <Col className="gutter-row" span={12}>
          <Title level={5}>Наименование</Title>
          <Text>
            <Tag>{record?.name}</Tag>
          </Text>
          <Title level={5}>Описание</Title>
          <Text>
            <Tag>{record?.description}</Tag>
          </Text>
          <Title level={5}>Порядок</Title>
          <Text>
            <Tag>{record?.sequence}</Tag>
          </Text>
          <Title level={5}>Документ</Title>
          <Text>
            <Tag>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={record?.file?.url}
              >
                Ссылка
              </a>
            </Tag>
          </Text>
          <Title level={5}>Обложка</Title>
          <Image src={record?.cover?.url} />
        </Col>
      </Row>
    </Show>
  );
};
