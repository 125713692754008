import { memo } from "react";

import { Button, Form, Input, Space } from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import styles from "../../pages/products/create.module.scss";
import BlockPlatformTypes from "./BlockPlatformTypes";

const BlockPlatform = ({ typesList, name, ...restField }: any) => {
  return (
    <Form.List {...restField} name={name}>
      {(fields, { add, remove }) => (
        <>
          <div className={styles.arrayProductsPlatforms}>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                style={{
                  width: 400,
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 8,
                  backgroundColor: "#ddd",
                  borderRadius: 20,
                  padding: 15,
                }}
                align="baseline"
              >
                <MinusCircleOutlined onClick={() => remove(name)} />

                <Form.Item
                  {...restField}
                  label="Выбор чего?"
                  name={[name, "title"]}
                  rules={[{ required: true, message: "Missing title" }]}
                >
                  <Input placeholder="Например: 'Выбор типа корпуса'" />
                </Form.Item>
                <Form.Item
                  {...restField}
                  label="Ключ"
                  name={[name, "key"]}
                  rules={[
                    {
                      required: true,
                      message: "Missing key",
                    },
                  ]}
                >
                  <Input placeholder="Ключ" />
                </Form.Item>
                <BlockPlatformTypes
                  {...restField}
                  name={[name, "types"]}
                  typesList={typesList}
                />
              </Space>
            ))}
          </div>
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              Добавить платформу
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default memo(BlockPlatform);
