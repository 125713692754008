import { IResourceComponentsProps } from "@pankod/refine-core";
import { Edit, Form, useForm, Select } from "@pankod/refine-antd";

import { Statuses } from "interfaces/deals/status.enum";
import { Stages } from "interfaces/deals/stages.enum";
import { useState } from "react";

import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";

export const DealsEdit: React.FC<IResourceComponentsProps> = () => {
  const [comments, setComments] = useState(false);
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  const { formProps, saveButtonProps } = useForm({
    resource: "sales/deals",
  });

  return (
    <Edit
      pageHeaderProps={{ title: "Изменение сделки", breadcrumb: {} }}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item label="Статус сделки" name="status">
          <Select
            defaultValue={+formProps.initialValues?.status}
            onChange={(value) => setComments(`${value}` === "rejected")}
          >
            {Object.keys(Statuses)
              .filter((v) => isNaN(Number(v)))
              .map((status) => (
                <Select.Option value={status}>
                  {Statuses[status as keyof typeof Statuses]}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item label="Этап сделки" name="stage">
          <Select defaultValue={+formProps.initialValues?.stage}>
            {Object.keys(Stages)
              .filter((v) => isNaN(Number(v)))
              .map((stage) => (
                <Select.Option value={Stages[stage as keyof typeof Stages]}>
                  {stage}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        {comments && (
          <Form.Item
            label="Комментарии отказа"
            name="commentsCancel"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <ReactMde
              selectedTab={selectedTab}
              onTabChange={setSelectedTab}
              generateMarkdownPreview={(markdown) =>
                Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
              }
            />
          </Form.Item>
        )}
      </Form>
    </Edit>
  );
};
