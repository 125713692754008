import { IResourceComponentsProps } from "@pankod/refine-core";
import { Edit, Form, Select, useForm } from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

export enum Statuses {
  "В обработке",
  "Отменено",
  "Выполнено",
}

export const OrdersEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm({});

  return (
    <Edit
      pageHeaderProps={{ title: "Изменение заказа", breadcrumb: {} }}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item label="Статус" name="status">
          <Select defaultValue={+formProps.initialValues?.status}>
            {Object.keys(Statuses)
              .filter((v) => isNaN(Number(v)))
              .map((status) => (
                <Select.Option value={Statuses[status as any]}>
                  {status}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Edit>
  );
};
