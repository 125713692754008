import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { Show, Typography, Tag, Row, Col } from "@pankod/refine-antd";

import styles from "./create.module.scss";

const { Title, Text } = Typography;

export const BannersShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<any>({
    resource: "banners",
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show
      pageHeaderProps={{ title: "Просмотр Баннера", breadcrumb: {} }}
      isLoading={isLoading}
    >
      <Row>
        <Col className="gutter-row" span={12}>
          <Title level={5}>Ключ</Title>
          <Text>
            <Tag>{record?.key}</Tag>
          </Text>
          <Title level={5}>Ссылка на ресурс, куда ведет баннер</Title>
          <Text>
            <a href={record?.url} target="_blank" rel="noopener noreferrer">
              <Tag color="green">{record?.url}</Tag>
            </a>
          </Text>
          {record?.file && (
            <>
              <Title level={5}>Превью</Title>
              <span style={{ color: "red" }}>
                *Это приблизительный вариант отображения. Текст отличается
                внешним видом.
              </span>
              <div
                className={styles.wrapper}
                style={{
                  backgroundImage: `url(${record?.file?.url})`,
                }}
              >
                {record?.title && (
                  <h3 className={styles.wrapper_title}>{record?.title}</h3>
                )}
                {record?.buttonText && (
                  <button type="button" className={styles.wrapper_button}>
                    {record?.buttonText}
                  </button>
                )}
              </div>
            </>
          )}
        </Col>
      </Row>
    </Show>
  );
};
