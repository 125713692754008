import {
  IResourceComponentsProps,
  usePermissions,
  useShow,
} from "@pankod/refine-core";
import { Show, Typography, Row, Col, Descriptions } from "@pankod/refine-antd";
import { format } from "date-fns";
import { Statuses } from "./edit";
import Cookies from "js-cookie";
import { UsersRo } from "interfaces/users/types/ro/users.ro";

const { Title } = Typography;

export const OrdersShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<any>({});
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { isSuccess } = usePermissions();

  const user = JSON.parse(Cookies.get("user") || "{}") as UsersRo;

  return (
    <Show
      pageHeaderProps={{ title: "Просмотр заказа", breadcrumb: {} }}
      isLoading={isLoading}
      canEdit={
        isSuccess ||
        record?.partner?.company?.manager?.id === user?.id ||
        record?.partner?.company?.employeer?.id === user?.id
      }
    >
      <Row>
        <Col className="gutter-row" span={12}>
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label="ID" span={12}>
              {record?.id}
            </Descriptions.Item>

            {record?.comment && (
              <Descriptions.Item label="Комментарий" span={12}>
                {record?.comment}
              </Descriptions.Item>
            )}
            {record?.created_at && (
              <Descriptions.Item label="Дата создания" span={12}>
                {format(new Date(record?.created_at), "dd.MM.yyyy")}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Статус" span={12}>
              {Statuses[record?.status]}
            </Descriptions.Item>
          </Descriptions>

          <Title level={3} style={{ marginTop: 50 }}>
            Комплектации
          </Title>

          {record?.complectations?.map((complectation: any) => (
            <Descriptions layout="vertical" bordered style={{ marginTop: 20 }}>
              <Descriptions.Item label="Количество" span={12}>
                {complectation?.count}
              </Descriptions.Item>
              <Descriptions.Item label="Тип продукта" span={12}>
                {`${complectation?.components?.productType?.name} - ${complectation?.components?.productSeries?.name}`}
              </Descriptions.Item>
              <Descriptions.Item label="Платформа" span={12}>
                {Object.values(complectation?.components?.platforms).map(
                  (item: any) => (
                    <>
                      {item?.title}: {item?.name}
                      <br />
                    </>
                  )
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Комплектующие" span={12}>
                {Object.values(complectation?.components?.accessories).map(
                  (item: any) => (
                    <>
                      <b>{item?.type}</b>: {item?.name}
                      <br />
                    </>
                  )
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Цена" span={12}>
                {
                  Object.values(complectation?.components?.accessories).reduce(
                    (acc, a: any) => acc + a.price,
                    0
                  ) as number
                }
              </Descriptions.Item>
            </Descriptions>
          ))}
        </Col>
      </Row>
    </Show>
  );
};
