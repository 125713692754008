import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { Show, Row, Col, Descriptions, Typography } from "@pankod/refine-antd";

import { ManufacturingTypesRo } from "interfaces/manufacturingTypes/types/ro/manufacturingTypes.ro";

import styles from "./types.module.scss";

const { Title } = Typography;

export const ManufacturingTypesShow: React.FC<
  IResourceComponentsProps
> = () => {
  const { queryResult } = useShow<ManufacturingTypesRo>({});
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show
      pageHeaderProps={{ title: "Просмотр типа продукции", breadcrumb: {} }}
      isLoading={isLoading}
    >
      <Row>
        <Col className="gutter-row" span={12}>
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label="Название" span={12}>
              {record?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Описание" span={12}>
              <div
                dangerouslySetInnerHTML={{ __html: `${record?.description}` }}
              />
            </Descriptions.Item>
          </Descriptions>

          <Descriptions
            layout="vertical"
            title="Дополнительная информация"
            bordered
            style={{ marginTop: 20 }}
          >
            {record?.additionalInfo?.map(({ title, description }) => (
              <Descriptions.Item label={title} span={12}>
                {description}
              </Descriptions.Item>
            ))}
          </Descriptions>
          {record?.file && (
            <div style={{ marginTop: 20 }}>
              <Title level={5}>Превью</Title>
              <div
                className={styles.wrapper}
                style={{
                  backgroundImage: `url(${record?.file?.url})`,
                }}
              />
            </div>
          )}
        </Col>
      </Row>
    </Show>
  );
};
