import { IResourceComponentsProps, usePermissions } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  Space,
  DeleteButton,
  EditButton,
  ShowButton,
} from "@pankod/refine-antd";

export const ProductsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    resource: "products",
    initialPageSize: 10,
    initialCurrent: 1,
  });

  const { data, count } = (tableProps.dataSource as any) || [];

  const { isSuccess } = usePermissions();

  return (
    <List>
      <Table
        {...tableProps}
        rowKey="slug"
        dataSource={data}
        pagination={{ pageSize: 10, total: count }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="key"
          key="key"
          title="Ключ"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title="Название"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="created_at"
          key="created_at"
          title="Дата создания"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          title="Управление"
          dataIndex="actions"
          render={(_, record: any) => (
            <Space>
              {isSuccess && (
                <EditButton hideText size="small" recordItemId={record.key} />
              )}
              <ShowButton hideText size="small" recordItemId={record.key} />
              {isSuccess && (
                <DeleteButton hideText size="small" recordItemId={record.key} />
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
