import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  Space,
  DeleteButton,
  ShowButton,
} from "@pankod/refine-antd";
import {
  SettingsDataRo,
  SettingsRo,
} from "interfaces/settings/types/ro/settings.ro";
import { Types } from "interfaces/contactUs/types/enums/types.enum";

export const ContactUsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    resource: "support/contactUs",
    initialPageSize: 10,
    initialCurrent: 1,
  });

  const { data, count } =
    (tableProps.dataSource as unknown as SettingsDataRo) || [];

  return (
    <List>
      <Table
        {...tableProps}
        rowKey="slug"
        dataSource={data}
        pagination={{ pageSize: 10, total: count }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="type"
          key="type"
          title="Тип обращения"
          render={(value: keyof typeof Types) => (
            <TextField value={Types[value]} />
          )}
        />
        <Table.Column
          dataIndex="theme"
          key="theme"
          title="Тема"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column<SettingsRo>
          title="Управление"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
