import { IResourceComponentsProps, usePermissions } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
} from "@pankod/refine-antd";
import { UsersRo } from "interfaces/users/types/ro/users.ro";
import { Role, RoleObject } from "interfaces/users/roles/role.enum";

export const PartnersList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<UsersRo>({
    resource: "partners",
    initialPageSize: 10,
    initialCurrent: 1,
  });
  const { isSuccess } = usePermissions();

  const { data, count } = (tableProps.dataSource as any) || [];

  return (
    <List
      pageHeaderProps={{
        title: "Пользователи",
        subTitle: "Партнеры компаний",
      }}
    >
      <Table
        {...tableProps}
        rowKey="id"
        dataSource={data}
        pagination={{ pageSize: 10, total: count }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="fullName"
          key="fullName"
          title="ФИО"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="company"
          key="company"
          title="Компания"
          render={(value) => <TextField value={value?.name || "No name"} />}
        />
        <Table.Column
          dataIndex="email"
          key="email"
          title="Email"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="phone"
          key="phone"
          title="Телефон"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="role"
          key="role"
          title="Роль"
          render={(role) => (
            <TextField
              value={RoleObject[Role[role] as keyof typeof RoleObject]}
            />
          )}
        />
        <Table.Column<UsersRo>
          title="Управление"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              {isSuccess && (
                <EditButton hideText size="small" recordItemId={record.id} />
              )}
              <ShowButton hideText size="small" recordItemId={record.id} />
              {isSuccess && (
                <DeleteButton hideText size="small" recordItemId={record.id} />
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
