import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  Upload,
  UploadFile,
  useForm,
} from "@pankod/refine-antd";

import { PlusOutlined } from "@ant-design/icons";

import "react-mde/lib/styles/css/react-mde-all.css";
import { useCallback, useEffect, useState } from "react";
import { request } from "api/Request";

const { TextArea } = Input;

export const EventsEdit: React.FC<IResourceComponentsProps> = () => {
  const { form, formProps, saveButtonProps } = useForm({ redirect: "list" });

  const [defaultFileList, setDefaultFileList] = useState<UploadFile[]>([]);

  const upload = useCallback(
    async (options) => {
      const { onSuccess, onError, file } = options;

      const formData = new FormData();

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      formData.append("files", file);

      try {
        const { data } = await request.post(
          `${process.env.REACT_APP_API}/uploads`,
          formData,
          config
        );

        onSuccess("Ok");

        form.setFieldsValue({ file: data[0].id });
      } catch (err) {
        onError({ err });
      }
    },
    [form]
  );

  useEffect(() => {
    if (formProps?.initialValues?.file) {
      setDefaultFileList([
        {
          uid: "-2",
          name: formProps?.initialValues?.file.original_filename,
          status: "done",
          url: formProps?.initialValues?.file.url,
        },
      ]);
    }
  }, [formProps?.initialValues?.file]);

  return (
    <Edit
      pageHeaderProps={{ title: "Изменение мероприятия", breadcrumb: {} }}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Название мероприятия"
          name="title"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="title" maxLength={300} />
        </Form.Item>
        <Form.Item
          label="Короткое название мероприятия"
          name="shortTitle"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="shortTitle" maxLength={100} />
        </Form.Item>

        <Form.Item
          label="Описание мероприятия"
          name="description"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <TextArea name="description" />
        </Form.Item>

        <Form.Item
          label="Дата"
          name="date"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="date" type="date" />
        </Form.Item>

        <Form.Item
          label="Время начала"
          name="timeStart"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="time" name="timeStart" />
        </Form.Item>

        <Form.Item
          label="Время окончания"
          name="timeEnd"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="time" name="timeEnd" />
        </Form.Item>

        <Form.Item
          label="Адрес"
          name="address"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="address" maxLength={150} />
        </Form.Item>

        <Form.Item
          name="file"
          label="Обложка"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Upload
            accept="image/*"
            listType="picture-card"
            maxCount={1}
            defaultFileList={defaultFileList}
            customRequest={(props) => upload(props)}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Загрузка обложки
              </div>
            </div>
          </Upload>
        </Form.Item>

        <Form.Item label="Ссылка на ВК" name={["socials", "vk"]}>
          <Input name="socials.vk" maxLength={300} />
        </Form.Item>
        <Form.Item label="Ссылка на Instagram" name={["socials", "instagram"]}>
          <Input name="socials.instagram" maxLength={300} />
        </Form.Item>
        <Form.Item label="Ссылка на Telegram" name={["socials", "telegram"]}>
          <Input name="socials.telegram" maxLength={300} />
        </Form.Item>
        <Form.Item label="Ссылка на OK" name={["socials", "ok"]}>
          <Input name="socials.ok" maxLength={300} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
