import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  InputNumber,
  Progress,
  Upload,
  useForm,
} from "@pankod/refine-antd";

import { request } from "api/Request";

import { PlusOutlined } from "@ant-design/icons";
import { useCallback, useState } from "react";

export const MaterialsCreate: React.FC<IResourceComponentsProps> = () => {
  const [defaultFileList, setDefaultFileList] = useState({
    file: [],
    cover: [],
  });
  const [progress, setProgress] = useState({ file: 0, cover: 0 });

  const { form, formProps, saveButtonProps } = useForm({
    resource: "marketing/materials",
    redirect: "list",
  });

  const beforeUpload = useCallback(
    (file) => file.type === "application/pdf" || Upload.LIST_IGNORE,
    []
  );

  const handleOnChange = useCallback(
    ({ fileList }: any, type: "file" | "cover") => {
      setDefaultFileList((prevLists) => ({ ...prevLists, [type]: fileList }));
    },
    []
  );

  const upload = useCallback(
    async (options, type: "file" | "cover") => {
      const { onSuccess, onError, file, onProgress } = options;

      const formData = new FormData();

      const config = {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (event: any) => {
          const percent = Math.floor((event.loaded / event.total) * 100);

          setProgress((prevProgress) => ({ ...prevProgress, [type]: percent }));

          if (percent === 100) {
            setTimeout(
              () =>
                setProgress((prevProgress) => ({ ...prevProgress, [type]: 0 })),
              1000
            );
          }
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };

      formData.append("files", file);

      try {
        const { data } = await request.post(
          `${process.env.REACT_APP_API}/uploads`,
          formData,
          config
        );

        onSuccess("Ok");

        form.setFieldsValue({ [type]: data[0].id });
      } catch (err) {
        onError({ err });
      }
    },
    [form]
  );

  return (
    <Create
      pageHeaderProps={{ title: "Создание материала", breadcrumb: {} }}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Наименование"
          name="name"
          rules={[
            {
              required: true,
              max: 100,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Описание"
          name="description"
          rules={[
            {
              required: true,
              max: 100,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Порядковый номер"
          name="sequence"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item name="cover" label="Обложка">
          <Upload
            accept="image/*"
            listType="picture-card"
            maxCount={1}
            onChange={(props) => handleOnChange(props, "cover")}
            defaultFileList={defaultFileList.file}
            customRequest={(props) => upload(props, "cover")}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Загрузка обложки
              </div>
            </div>
          </Upload>
          {progress.cover > 0 ? <Progress percent={progress.cover} /> : null}
        </Form.Item>
        <Form.Item name="file" label="Файл">
          <Upload
            listType="picture-card"
            accept="application/pdf"
            maxCount={1}
            onChange={(props) => handleOnChange(props, "file")}
            defaultFileList={defaultFileList.file}
            customRequest={(props) => upload(props, "file")}
            {...beforeUpload}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Загрузка файла (PDF)
              </div>
            </div>
          </Upload>
          {progress.file > 0 ? <Progress percent={progress.file} /> : null}
        </Form.Item>
      </Form>
    </Create>
  );
};
