import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { Show, Typography, Tag, Row, Col, Card } from "@pankod/refine-antd";
import { Types } from "interfaces/contactUs/types/enums/types.enum";

const { Title, Text } = Typography;

export const ContactUsShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<any>({
    resource: "support/contactUs",
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show
      pageHeaderProps={{ title: "Просмотр обращения", breadcrumb: {} }}
      isLoading={isLoading}
    >
      <Row>
        <Col className="gutter-row" span={12}>
          <Title level={5}>Тип обращения</Title>
          <Text>
            <Tag>{Types[record?.type as unknown as keyof typeof Types]}</Tag>
          </Text>
          <Title level={5}>Тема</Title>
          <Text>
            <Tag>{record?.theme}</Tag>
          </Text>
          <Title level={5}>Описание</Title>
          <Text>
            <Tag>{record?.description}</Tag>
          </Text>
          {record?.file && (
            <>
              <Title level={5}>Файл</Title>
              <Text>
                <a
                  href={record?.file.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Tag>{record?.file.original_filename}</Tag>
                </a>
              </Text>
            </>
          )}
        </Col>
        <Col className="gutter-row" span={12}>
          {record?.partner && (
            <Card>
              <Title level={5}>ФИО партнера</Title>
              <Text>
                <Tag>{record?.partner?.fullName}</Tag>
              </Text>
              <Title level={5}>Тел. партнера</Title>
              <Text>
                <Tag>{record?.partner?.phone}</Tag>
              </Text>
              <Title level={5}>Email партнера</Title>
              <Text>
                <Tag>{record?.partner?.email}</Tag>
              </Text>
            </Card>
          )}
          {record?.employeer && (
            <Card>
              <Title level={5}>ФИО сотрудника</Title>
              <Text>
                <Tag>{record?.employeer?.fullName}</Tag>
              </Text>
              <Title level={5}>Тел. сотрудника</Title>
              <Text>
                <Tag>{record?.employeer?.phone}</Tag>
              </Text>
              <Title level={5}>Email сотрудника</Title>
              <Text>
                <Tag>{record?.employeer?.email}</Tag>
              </Text>
            </Card>
          )}
          {(record?.partner?.company || record?.employeer?.company) && (
            <Card style={{ marginTop: "20px" }}>
              <Title level={5}>Название компании</Title>
              <Text>
                <Tag>
                  {record?.partner?.company.name ||
                    record?.employeer?.company.name}
                </Tag>
              </Text>
              <Title level={5}>Тел. компании</Title>
              <Text>
                <Tag>
                  {record?.partner?.company?.phone ||
                    record?.employeer?.company?.phone}
                </Tag>
              </Text>
              <Title level={5}>Email компании</Title>
              <Text>
                <Tag>
                  {record?.partner?.company?.email ||
                    record?.employeer?.company?.email}
                </Tag>
              </Text>
            </Card>
          )}
        </Col>
      </Row>
    </Show>
  );
};
