import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Button,
  Create,
  Form,
  Input,
  InputNumber,
  Space,
  Upload,
  UploadFile,
  useForm,
} from "@pankod/refine-antd";

import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";

import "react-mde/lib/styles/css/react-mde-all.css";
import { useCallback, useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { request } from "api/Request";

import styles from "./create.module.scss";
import BlockPlatform from "components/BlockPlatform";

import { useSelect } from "@pankod/refine-core";

export type Component = {
  id: number;
  key: string;
  name: string;
  fullName: string;
  type: string;
  description: string;
  photoUrl: string;
  active: boolean;
  price: number;
  created_at: Date;
};

export const ProductsCreate: React.FC<IResourceComponentsProps> = () => {
  const { form, formProps, saveButtonProps } = useForm({
    resource: "products",
    redirect: "list",
  });

  // markdown
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  // file upload

  const [defaultFileList] = useState<UploadFile[]>([]);
  const [defaultFileListArray] = useState<UploadFile[][]>([]);

  const upload = useCallback(
    async (options) => {
      const { onSuccess, onError, file } = options;

      const formData = new FormData();

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      formData.append("files", file);

      try {
        const { data } = await request.post(
          `${process.env.REACT_APP_API}/uploads`,
          formData,
          config
        );

        onSuccess("Ok");

        form.setFieldsValue({ photoUrl: data[0].id });
      } catch (err) {
        onError({ err });
      }
    },
    [form]
  );

  const uploadArray = useCallback(
    async (options, name) => {
      const { onSuccess, onError, file } = options;

      const formData = new FormData();

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      formData.append("files", file);

      try {
        const { data } = await request.post(
          `${process.env.REACT_APP_API}/uploads`,
          formData,
          config
        );

        onSuccess("Ok");

        const prevValue = form.getFieldValue("types");

        prevValue[name].photoUrl = `${data[0].id}`;

        form.setFieldsValue({ types: prevValue });
      } catch (err) {
        onError({ err });
      }
    },
    [form]
  );

  const {
    queryResult: { data },
  } = useSelect({
    resource: "components",
    fetchSize: 500,
  });

  const componentsList =
    (data?.data as unknown as { data: Component[] })?.data || [];

  const typesList = [
    ...(new Set(componentsList.map((component) => component.type)) as any),
  ].map((type) => {
    const children = componentsList
      .filter((component) => component.type === type)
      .map((component) => ({ value: component.key, title: component.name }));

    return {
      title: type,
      value: type,
      selectable: false,
      children,
    };
  });

  return (
    <Create
      pageHeaderProps={{ title: "Создание продукта", breadcrumb: {} }}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Ключ"
          name="key"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Название"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="title" />
        </Form.Item>
        <Form.Item
          label="Описание"
          name="description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>
        <Form.Item
          name="photoUrl"
          label="Файл"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Upload
            accept="image/*"
            listType="picture-card"
            maxCount={1}
            defaultFileList={defaultFileList}
            customRequest={(props) => upload(props)}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Загрузка обложки
              </div>
            </div>
          </Upload>
        </Form.Item>
        <Form.List name="types">
          {(fields, { add, remove }) => (
            <>
              <div className={styles.arrayProducts}>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Space
                    key={key}
                    style={{
                      width: 400,
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 8,
                      backgroundColor: "#eeeeee",
                      borderRadius: 20,
                      padding: 15,
                    }}
                    align="baseline"
                  >
                    <MinusCircleOutlined onClick={() => remove(name)} />

                    <Form.Item
                      {...restField}
                      label="Ключ"
                      name={[name, "key"]}
                      rules={[{ required: true, message: "Missing key" }]}
                    >
                      <Input placeholder="Ключ" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Название"
                      name={[name, "name"]}
                      rules={[{ required: true, message: "Missing name" }]}
                    >
                      <Input placeholder="Название" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Сортировка"
                      name={[name, "sort"]}
                      rules={[{ required: true, message: "Missing sort" }]}
                    >
                      <InputNumber placeholder="Сортировка" />
                    </Form.Item>
                    <Form.Item
                      name={[name, "photoUrl"]}
                      label="Картинка"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Upload
                        accept="image/*"
                        listType="picture-card"
                        maxCount={1}
                        defaultFileList={defaultFileListArray[index]}
                        customRequest={(props) => uploadArray(props, name)}
                      >
                        <div>
                          <PlusOutlined />
                          <div
                            style={{
                              marginTop: 8,
                            }}
                          >
                            Загрузка обложки
                          </div>
                        </div>
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Короткое описание"
                      name={[name, "shortDescription"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing short description",
                        },
                      ]}
                    >
                      <Input placeholder="Короткое описание" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Описание"
                      name={[name, "description"]}
                      rules={[
                        { required: true, message: "Missing description" },
                      ]}
                    >
                      <ReactMde
                        selectedTab={selectedTab}
                        onTabChange={setSelectedTab}
                        generateMarkdownPreview={(markdown) =>
                          Promise.resolve(
                            <ReactMarkdown>{markdown}</ReactMarkdown>
                          )
                        }
                      />
                    </Form.Item>
                    <BlockPlatform
                      {...restField}
                      name={[name, "platforms"]}
                      typesList={typesList}
                    />
                  </Space>
                ))}
              </div>
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Добавить серию
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Create>
  );
};
