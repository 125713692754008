import { AuthProvider } from "@pankod/refine-core";
import { req, request } from "api/Request";
import { Role } from "interfaces/users/roles/role.enum";
import Cookies from "js-cookie";

export interface ILoginForm {
  email: string;
  password: string;
}

const authProvider: AuthProvider = {
  login: async ({ email, password }: ILoginForm) => {
    const {
      data: { accessToken, refreshToken },
    } = await request.post(`${process.env.REACT_APP_API!}/lk/auth/login`, {
      email,
      password,
    });

    const isAuth = accessToken && refreshToken;

    if (isAuth) {
      req.setHeader(accessToken);

      const { data } = await request.get(
        `${process.env.REACT_APP_API!}/users/info`
      );

      if (![Role.Admin, Role.Manager].includes(data?.role))
        return Promise.reject({
          name: "Ошибка авторизации",
          message: "У вас нет доступа",
        });

      Cookies.set("accessToken", accessToken);
      Cookies.set("user", JSON.stringify(data || {}));
      Cookies.set("refreshToken", refreshToken);

      return Promise.resolve();
    }

    return Promise.reject({
      name: "Ошибка авторизации",
      message: "Почта или пароль не верные",
    });
  },

  logout: () => {
    Cookies.remove("accessToken");
    Cookies.remove("role");
    Cookies.remove("refreshToken");

    req.setHeader("");

    return Promise.resolve();
  },

  checkAuth: async () => {
    const { data } = await request.post(
      `${process.env.REACT_APP_API!}/lk/auth/check-tokens`
    );

    return data ? Promise.resolve() : Promise.reject();
  },

  checkError: (error: { status: number }) =>
    error.status === 401 ? Promise.reject() : Promise.resolve(),

  getPermissions: () => {
    const role = JSON.parse(Cookies.get("user") || "{}").role || 0;

    return Role.Admin === +role ? Promise.resolve(true) : Promise.reject(false);
  },
};

export default authProvider;
