import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { Show, Typography, Tag, Card, Row, Col } from "@pankod/refine-antd";
import { format } from "date-fns";

const { Title, Text } = Typography;

export const FundShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow({});
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show
      pageHeaderProps={{
        title: "Просмотр заявки на маркетинговый бюджет",
        breadcrumb: {},
      }}
      isLoading={isLoading}
    >
      <Row>
        <Col className="gutter-row" span={12}>
          <Title level={5}>Бюджет</Title>
          <Text>
            <Tag>{record?.budget}</Tag>
          </Text>
          <Title level={5}>Дата проведения</Title>
          <Text>
            <Tag>
              {record?.date && format(new Date(record?.date), "dd.MM.yyyy")}
            </Tag>
          </Text>
          <Title level={5}>О мероприятии</Title>
          <Text>
            <Tag>{record?.event}</Tag>
          </Text>
          <Title level={5}>Ожидание результата</Title>
          <Text>
            <Tag>{record?.result}</Tag>
          </Text>
        </Col>
        <Col className="gutter-row" span={12}>
          <Card>
            <Title level={5}>Партнер</Title>
            <Text>
              <Tag>{record?.company.name}</Tag>
            </Text>
            <Title level={5}>Почта партнера</Title>
            <Text>
              <Tag>{record?.company.email}</Tag>
            </Text>
            <Title level={5}>Телефон партнера</Title>
            <Text>
              <Tag>{record?.company.phone}</Tag>
            </Text>
          </Card>
        </Col>
      </Row>
    </Show>
  );
};
