import { IResourceComponentsProps, usePermissions } from "@pankod/refine-core";
import {
  DeleteButton,
  List,
  ShowButton,
  Space,
  Table,
  TextField,
  useTable,
} from "@pankod/refine-antd";
import { format } from "date-fns";
import Cookies from "js-cookie";
import { UsersRo } from "interfaces/users/types/ro/users.ro";

export const FundsList: React.FC<IResourceComponentsProps> = () => {
  const { isSuccess } = usePermissions();

  const { tableProps } = useTable({
    resource: "marketing/funds",
    initialPageSize: 10,
    initialCurrent: 1,
  });

  const { data, count } = (tableProps.dataSource as any) || [];

  const user = JSON.parse(Cookies.get("user") || "{}") as UsersRo;

  return (
    <List>
      <Table
        {...tableProps}
        rowKey="slug"
        dataSource={data}
        pagination={{ pageSize: 10, total: count }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="date"
          key="date"
          title="Дата проведения"
          render={(value) => (
            <TextField value={format(new Date(value), "dd.MM.yyyy")} />
          )}
        />
        <Table.Column
          dataIndex="company"
          key="company"
          title="Партнер"
          render={(value) => <TextField value={value?.name} />}
        />
        <Table.Column
          dataIndex="company"
          key="company"
          title="Почта партнера"
          render={(value) => <TextField value={value?.email} />}
        />
        <Table.Column
          dataIndex="budget"
          key="budget"
          title="Бюджет"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column<any>
          title="Управление"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <ShowButton hideText size="small" recordItemId={record.id} />
              {(record?.company?.manager?.id === user?.id || isSuccess) && (
                <DeleteButton hideText size="small" recordItemId={record.id} />
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
