import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  Space,
  DeleteButton,
  EditButton,
  ShowButton,
} from "@pankod/refine-antd";
import { format } from "date-fns";

export const EventsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    initialPageSize: 10,
    initialCurrent: 1,
  });

  const { data, count } = (tableProps.dataSource as any) || [];

  return (
    <List>
      <Table
        {...tableProps}
        rowKey="slug"
        dataSource={data}
        pagination={{ pageSize: 10, total: count }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="title"
          key="title"
          title="Заголовок"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="date"
          key="date"
          title="Дата"
          render={(value) => (
            <TextField value={format(new Date(value), "dd.MM.yyyy")} />
          )}
        />
        <Table.Column
          dataIndex="time"
          key="time"
          title="Время"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="address"
          key="address"
          title="Адрес"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          title="Управление"
          dataIndex="actions"
          render={(_, record: any) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
