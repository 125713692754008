import {
  IResourceComponentsProps,
  usePermissions,
  useShow,
} from "@pankod/refine-core";
import {
  Show,
  Typography,
  Tag,
  Card,
  Row,
  Col,
  Checkbox,
  Table,
  TextField,
} from "@pankod/refine-antd";
import { Status } from "interfaces/companies/enums/status.enum";
import Cookies from "js-cookie";
import { UsersRo } from "interfaces/users/types/ro/users.ro";

const { Title, Text } = Typography;

export const CompaniesShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow({});
  const { data, isLoading } = queryResult;

  const record = data?.data;

  const { isSuccess } = usePermissions();

  const user = JSON.parse(Cookies.get("user") || "{}") as UsersRo;

  return (
    <Show
      pageHeaderProps={{ title: "Просмотр компании", breadcrumb: {} }}
      isLoading={isLoading}
      canEdit={record?.manager?.id === user?.id || isSuccess}
    >
      <Row>
        <Col className="gutter-row" span={12}>
          <Title level={5}>Название</Title>
          <Text>
            <Tag>{record?.name}</Tag>
          </Text>
          <Title level={5}>ИНН</Title>
          <Text>
            <Tag>{record?.inn}</Tag>
          </Text>
          <Title level={5}>Email</Title>
          <Text>
            <Tag>{record?.email}</Tag>
          </Text>
          <Title level={5}>Юридический адрес</Title>
          <Text>
            <Tag>{record?.legalAddress}</Tag>
          </Text>
          <Title level={5}>Фактический адрес</Title>
          <Text>
            <Tag>{record?.actualAddress}</Tag>
          </Text>
        </Col>
        <Col className="gutter-row" span={12}>
          <Card>
            <Title level={5}>ФИО пользователя</Title>
            <Text>
              <Tag>{record?.partner?.fullName}</Tag>
            </Text>
            <Title level={5}>Тел. пользователя</Title>
            <Text>
              <Tag>{record?.partner?.phone}</Tag>
            </Text>
            <Title level={5}>Email пользователя</Title>
            <Text>
              <Tag>{record?.partner?.email}</Tag>
            </Text>
          </Card>
          <Card style={{ marginTop: "20px" }}>
            <Title level={5}>Назначенный менеджер</Title>
            <Text>
              <Tag>{record?.manager?.fullName}</Tag>
            </Text>
          </Card>
          <Card style={{ marginTop: "20px" }}>
            <Title level={5}>Подтверждение</Title>
            <Checkbox disabled checked={!!record?.success}>
              Подтверждена
            </Checkbox>
            <Title style={{ marginTop: "20px" }} level={5}>
              Статус
            </Title>
            <Text>
              <Tag>{Status[record?.status as any]}</Tag>
            </Text>
          </Card>
        </Col>
      </Row>

      <Table
        dataSource={record?.employees}
        pagination={false}
        style={{ marginTop: 20 }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="fullName"
          key="fullName"
          title="ФИО"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="position"
          key="position"
          title="Должность"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="email"
          key="email"
          title="Почта"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="phone"
          key="phone"
          title="Телефон"
          render={(value) => <TextField value={value} />}
        />
      </Table>
    </Show>
  );
};
