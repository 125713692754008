import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  Space,
  DeleteButton,
  EditButton,
  ShowButton,
} from "@pankod/refine-antd";

export const BannersList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    resource: "banners",
    initialPageSize: 10,
    initialCurrent: 1,
  });

  const { data, count } = (tableProps.dataSource as any) || [];

  return (
    <List>
      <Table
        {...tableProps}
        rowKey="slug"
        dataSource={data}
        pagination={{ pageSize: 10, total: count }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="key"
          key="key"
          title="Ключ"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="title"
          key="title"
          title="Заголовок"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          title="Управление"
          dataIndex="actions"
          render={(_, record: any) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.key} />
              <ShowButton hideText size="small" recordItemId={record.key} />
              <DeleteButton hideText size="small" recordItemId={record.key} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
