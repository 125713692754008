import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  InputNumber,
  Upload,
  useForm,
  UploadFile,
} from "@pankod/refine-antd";

import { request } from "api/Request";

import { PlusOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";

export const MaterialsEdit: React.FC<IResourceComponentsProps> = () => {
  const [defaultFileList, setDefaultFileList] = useState<{
    cover: UploadFile[];
    file: UploadFile[];
  }>({
    file: [],
    cover: [],
  });

  const { form, formProps, saveButtonProps } = useForm({
    resource: "marketing/materials",
  });

  const beforeUpload = useCallback(
    (file) => file.type === "application/pdf" || Upload.LIST_IGNORE,
    []
  );

  const upload = useCallback(
    async (options, type: "file" | "cover") => {
      const { onSuccess, onError, file } = options;

      const formData = new FormData();

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      formData.append("files", file);

      try {
        const { data } = await request.post(
          `${process.env.REACT_APP_API}/uploads`,
          formData,
          config
        );

        onSuccess("Ok");

        form.setFieldsValue({ [type]: data[0].id });
      } catch (err) {
        onError({ err });
      }
    },
    [form]
  );

  useEffect(() => {
    setDefaultFileList((prevFiles) => {
      const newFiles = { ...prevFiles };

      if (formProps?.initialValues?.cover) {
        newFiles["cover"] = [
          {
            uid: "-2",
            name: formProps?.initialValues?.cover.original_filename,
            status: "done",
            url: formProps?.initialValues?.cover.url,
          },
        ];
      }

      if (formProps?.initialValues?.file) {
        newFiles["file"] = [
          {
            uid: "-3",
            name: formProps?.initialValues?.file.original_filename,
            status: "done",
            url: formProps?.initialValues?.file.url,
          },
        ];
      }

      return newFiles;
    });
  }, [formProps?.initialValues?.cover, formProps?.initialValues?.file]);

  return (
    <Edit
      pageHeaderProps={{ title: "Изменение материала", breadcrumb: {} }}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Наименование"
          name="name"
          rules={[
            {
              required: true,
              max: 100,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Описание"
          name="description"
          rules={[
            {
              required: true,
              max: 300,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Порядковый номер"
          name="sequence"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item name="cover" label="Обложка">
          <Upload
            accept="image/*"
            listType="picture-card"
            maxCount={1}
            defaultFileList={defaultFileList.cover}
            customRequest={(props) => upload(props, "cover")}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Загрузка обложки
              </div>
            </div>
          </Upload>
        </Form.Item>
        <Form.Item name="file" label="Файл">
          <Upload
            listType="picture-card"
            accept="application/pdf"
            maxCount={1}
            beforeUpload={beforeUpload}
            defaultFileList={defaultFileList.file}
            customRequest={(props) => upload(props, "file")}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Загрузка файла (PDF)
              </div>
            </div>
          </Upload>
        </Form.Item>
      </Form>
    </Edit>
  );
};
