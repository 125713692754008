import { IResourceComponentsProps } from "@pankod/refine-core";
import { Create, Form, Input, Upload, useForm } from "@pankod/refine-antd";
import { useCallback, useState } from "react";
import { request } from "api/Request";

import { PlusOutlined } from "@ant-design/icons";

export const WebinarsCreate: React.FC<IResourceComponentsProps> = () => {
  const { form, formProps, saveButtonProps } = useForm({
    redirect: "list",
  });

  const [defaultFileList, setDefaultFileList] = useState([]);

  const handleOnChange = useCallback(({ fileList }: any) => {
    setDefaultFileList(fileList);
  }, []);

  const upload = useCallback(
    async (options) => {
      const { onSuccess, onError, file } = options;

      const formData = new FormData();

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      formData.append("files", file);

      try {
        const { data } = await request.post(
          `${process.env.REACT_APP_API}/uploads`,
          formData,
          config
        );

        onSuccess("Ok");

        form.setFieldsValue({ file: data[0].id });
      } catch (err) {
        onError({ err });
      }
    },
    [form]
  );

  return (
    <Create
      pageHeaderProps={{ title: "Создание вебинара", breadcrumb: {} }}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Название"
          name="title"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="title" maxLength={100} />
        </Form.Item>
        <Form.Item
          label="Порядкоывй номер"
          name="sequence"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="sequence" type="number" />
        </Form.Item>
        <Form.Item
          label="Ссылка"
          name="url"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="url" placeholder="youtube" type="url" />
        </Form.Item>

        <Form.Item
          name="file"
          label="Обложка"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Upload
            accept="image/*"
            listType="picture-card"
            maxCount={1}
            onChange={(props) => handleOnChange(props)}
            defaultFileList={defaultFileList}
            customRequest={(props) => upload(props)}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Загрузка обложки
              </div>
            </div>
          </Upload>
        </Form.Item>
      </Form>
    </Create>
  );
};
