import { memo, useCallback, useEffect, useState } from "react";

import {
  Button,
  Form,
  FormInstance,
  FormProps,
  Input,
  Space,
  Upload,
  UploadFile,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

import { request } from "api/Request";

import styles from "./manufacturings.module.scss";
import ManufacturingsInfo from "./ManufacturingsInfo";

type ManuImage = {
  file: UploadFile;
  images: UploadFile[];
};

const Manufacturings = ({
  form,
  formProps,
}: {
  form: FormInstance<{}>;
  formProps: FormProps<{}>;
}) => {
  // markdown
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  const [defaultFileList, setDefaultFileList] = useState<ManuImage[]>([]);

  const upload = useCallback(
    async (options, index, field) => {
      const { onSuccess, onError, file } = options;

      const formData = new FormData();
      formData.append("files", file);

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      try {
        const { data } = await request.post(
          `${process.env.REACT_APP_API}/uploads`,
          formData,
          config
        );
        onSuccess("Ok");

        const items = form.getFieldValue("items");

        Object.assign(items, {
          [index]: { ...items[index], [field]: data[0].id },
        });
      } catch (err) {
        onError({ err });
      }
    },
    [form]
  );

  const uploadImages = useCallback(
    async (options, index, field) => {
      const { onSuccess, onError, file } = options;

      const formData = new FormData();
      formData.append("files", file);

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      try {
        const { data } = await request.post(
          `${process.env.REACT_APP_API}/uploads`,
          formData,
          config
        );
        onSuccess("Ok");

        const items = form.getFieldValue("items");

        setDefaultFileList((prev) => {
          const newList = Object.assign(prev, {
            [index]: {
              file: prev?.[index]?.file,
              images: [...(prev?.[index]?.images || []), data[0].id],
            },
          });

          const newItems = Object.assign(items, {
            [index]: {
              ...items[index],
              [field]: newList[index].images,
            },
          });

          console.log(newItems);
          return newList;
        });
      } catch (err) {
        onError({ err });
      }
    },
    [form]
  );

  const removeImage = useCallback(async (file) => {
    console.log(file);
  }, []);

  useEffect(() => {
    const defaultList = formProps?.initialValues?.items.map((item: any) => ({
      file: {
        uid: item?.file.id,
        name: item?.file.original_filename,
        status: "done",
        url: item?.file.url,
      },
      images: item?.images.map((image: any) => ({
        uid: image?.id,
        name: image?.original_filename,
        status: "done",
        url: image?.url,
      })),
    }));

    setDefaultFileList(defaultList);
  }, [formProps]);

  return (
    <Form.List name="items">
      {(fields, { add, remove }) => (
        <>
          <div className={styles.wrapper}>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                style={{
                  width: 430,
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 8,
                  backgroundColor: "#eeeeee",
                  borderRadius: 20,
                  padding: 15,
                }}
                align="baseline"
              >
                <MinusCircleOutlined onClick={() => remove(name)} />

                <Form.Item
                  {...restField}
                  label="Файл"
                  name={[name, "file"]}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Upload
                    accept="image/*"
                    listType="picture-card"
                    maxCount={1}
                    defaultFileList={
                      defaultFileList?.[name]?.file
                        ? [defaultFileList?.[name]?.file]
                        : []
                    }
                    customRequest={(props) => upload(props, name, "file")}
                  >
                    <div>
                      <PlusOutlined />
                      <div
                        style={{
                          marginTop: 8,
                        }}
                      >
                        Загрузка обложки
                      </div>
                    </div>
                  </Upload>
                </Form.Item>

                <Form.Item
                  {...restField}
                  label="Название"
                  name={[name, "name"]}
                  style={{
                    width: 400,
                  }}
                  rules={[{ required: true, message: "Missing name" }]}
                >
                  <Input placeholder="Например: 'Монитор RDW 2701 Q'" />
                </Form.Item>

                <Form.Item
                  {...restField}
                  label="Описание"
                  name={[name, "description"]}
                  style={{
                    width: 400,
                  }}
                  rules={[{ required: true, message: "Missing description" }]}
                >
                  <ReactMde
                    selectedTab={selectedTab}
                    onTabChange={setSelectedTab}
                    generateMarkdownPreview={(markdown) =>
                      Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                    }
                  />
                </Form.Item>

                <Form.Item
                  {...restField}
                  label="Основные характеристики"
                  name={[name, "specifications"]}
                  style={{
                    width: 400,
                  }}
                  rules={[
                    { required: true, message: "Missing specifications" },
                  ]}
                >
                  <Input placeholder="Например: '1920х1080@70 Гц, IPS, 1 мс, 1000 : 1, 250 Кд/м², 178°/178°, AHVA, HDMI, Display Port.'" />
                </Form.Item>

                <Form.Item
                  {...restField}
                  label="Изображения"
                  name={[name, "images"]}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Upload
                    accept="image/*"
                    listType="picture"
                    defaultFileList={defaultFileList?.[name]?.images || []}
                    onRemove={removeImage}
                    customRequest={(props) =>
                      uploadImages(props, name, "images")
                    }
                  >
                    <Button icon={<UploadOutlined />}>
                      Загрузить Изображения
                    </Button>
                  </Upload>
                </Form.Item>

                <ManufacturingsInfo {...restField} name={[name, "info"]} />
              </Space>
            ))}
          </div>
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              Добавить продукцию
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default memo(Manufacturings);
