import { IResourceComponentsProps, usePermissions } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  Space,
  DeleteButton,
  EditButton,
  ShowButton,
} from "@pankod/refine-antd";
import { format } from "date-fns";
import Cookies from "js-cookie";
import { UsersRo } from "interfaces/users/types/ro/users.ro";

export const OrdersList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    resource: "orders",
    initialPageSize: 10,
    initialCurrent: 1,
  });

  const { data, count } = (tableProps.dataSource as any) || [];

  const { isSuccess } = usePermissions();

  const user = JSON.parse(Cookies.get("user") || "{}") as UsersRo;

  return (
    <List>
      <Table
        {...tableProps}
        rowKey="slug"
        dataSource={data}
        pagination={{ pageSize: 10, total: count }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="created_at"
          key="created_at"
          title="Дата создания"
          render={(value) => (
            <TextField value={format(new Date(value), "dd.MM.yyyy")} />
          )}
        />
        <Table.Column
          title="Управление"
          dataIndex="actions"
          render={(_, record: any) => (
            <Space>
              {(isSuccess ||
                record?.partner?.company?.manager?.id === user?.id ||
                record?.partner?.company?.employeer?.id === user?.id) && (
                <EditButton hideText size="small" recordItemId={record.id} />
              )}
              <ShowButton hideText size="small" recordItemId={record.id} />
              {(isSuccess ||
                record?.partner?.company?.manager?.id === user?.id ||
                record?.partner?.company?.employeer?.id === user?.id) && (
                <DeleteButton hideText size="small" recordItemId={record.id} />
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
