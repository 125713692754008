import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  Progress,
  Upload,
  useForm,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";
import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";
import { useCallback, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";

import { request } from "api/Request";

export const SettingsCreate: React.FC<IResourceComponentsProps> = () => {
  const { form, formProps, saveButtonProps } = useForm({
    resource: "articles",
    redirect: "list",
  });

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [progress, setProgress] = useState(0);

  const beforeUpload = useCallback(
    (file) => file.type === "application/pdf" || Upload.LIST_IGNORE,
    []
  );

  const handleOnChange = useCallback(({ fileList }: any) => {
    setDefaultFileList(fileList);
  }, []);

  const upload = useCallback(
    async (options) => {
      const { onSuccess, onError, file, onProgress } = options;

      const formData = new FormData();

      const config = {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (event: any) => {
          const percent = Math.floor((event.loaded / event.total) * 100);

          setProgress(percent);

          if (percent === 100) {
            setTimeout(() => setProgress(0), 1000);
          }
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };

      formData.append("files", file);

      try {
        const { data } = await request.post(
          `${process.env.REACT_APP_API}/uploads`,
          formData,
          config
        );

        onSuccess("Ok");

        form.setFieldsValue({ file: data[0].id });
      } catch (err) {
        onError({ err });
      }
    },
    [form]
  );

  return (
    <Create
      pageHeaderProps={{ title: "Создание статьи", breadcrumb: {} }}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Ключ"
          name="key"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Содержимое"
          name="markup"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>
        <Form.Item name="file" label="Файл">
          <Upload
            listType="picture-card"
            accept="application/pdf"
            maxCount={1}
            onChange={(props) => handleOnChange(props)}
            defaultFileList={defaultFileList}
            customRequest={(props) => upload(props)}
            {...beforeUpload}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Загрузка файла (PDF)
              </div>
            </div>
          </Upload>
          {progress > 0 ? <Progress percent={progress} /> : null}
        </Form.Item>
      </Form>
    </Create>
  );
};
