import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  DeleteButton,
  List,
  Space,
  Table,
  TextField,
  useTable,
} from "@pankod/refine-antd";
import { format } from "date-fns";
import { Status } from "interfaces/companies/enums/status.enum";

export const CertificatesList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    resource: "certificates",
    initialPageSize: 10,
    initialCurrent: 1,
  });

  const { data, count } = (tableProps.dataSource as any) || [];

  return (
    <List>
      <Table
        {...tableProps}
        rowKey="slug"
        dataSource={data}
        pagination={{ pageSize: 10, total: count }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="company"
          key="company"
          title="ID партнера"
          render={(value) => <TextField value={value?.id || "Нет компании"} />}
        />
        <Table.Column
          dataIndex="company"
          key="company"
          title="Партнер"
          render={(value) => (
            <TextField value={value?.name || "Нет компании"} />
          )}
        />
        <Table.Column
          dataIndex="company"
          key="company"
          title="Статус сертификата"
          render={(value) => (
            <TextField
              value={value?.status ? Status[value?.status] : "Не известно"}
            />
          )}
        />
        <Table.Column
          dataIndex="created_at"
          key="created_at"
          title="Начало действия сертификата"
          render={(value) => (
            <TextField value={format(new Date(value), "dd.MM.yyyy")} />
          )}
        />
        <Table.Column
          dataIndex="date_end"
          key="date_end"
          title="Окончание действия сертификата"
          render={(value) => (
            <TextField value={format(new Date(value), "dd.MM.yyyy")} />
          )}
        />
        <Table.Column
          title="Управление"
          dataIndex="actions"
          render={(_, record: any) => {
            return (
              <Space>
                {!record?.company && (
                  <DeleteButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                  />
                )}
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
